import React from "react";
import Icon from "./icon";
import Container from "./container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import AnchorLink from 'react-anchor-link-smooth-scroll';

import * as styles from "../styles/components/page-banner.module.css";

const PageBanner = (props) => {

  const {
    text,
    anchorLinkText,
    image,
    imageMobile
  } = props;

  return (
    <div className={styles.root} id="pageBanner">
      {((image && image.asset) || (imageMobile && imageMobile.asset)) && (
        <div className={styles.image}>
          <picture>
            {image && image.asset && imageMobile && imageMobile.asset && (
              <>
              <source media="(orientation: landscape)" 
                srcset={imageUrlFor(buildImageObj(image))
                  .auto("format")
                  .url()} 
              />
              <source media="(orientation: portrait)" 
                srcset={imageUrlFor(buildImageObj(imageMobile))
                  .auto("format")
                  .url()} 
              />
              </>
            )}
            {image && image.asset && (
              <img
                src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
                alt={image.alt}
              />
            )}
            {(!image || !image.asset) && (imageMobile && imageMobile.asset) && (
              <img
                src={imageUrlFor(buildImageObj(imageMobile))
                .auto("format")
                .url()}
                alt={imageMobile.alt}
              />
            )}
          </picture>
        </div>
      )}
      {(text || anchorLinkText) && (
        <div className={styles.text}>
          {text && (
            <Container>
              <h1>{text}</h1>
            </Container>
          )}
          {anchorLinkText && (
            <div className={styles.anchorLink}>
              <Container>
                <AnchorLink offset="70" href="#main">{anchorLinkText}<Icon symbol="arrow-down" /></AnchorLink>
              </Container>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PageBanner;
