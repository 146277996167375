import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/next-page.module.css";

const NextPage = (props) => {

  const {
    link,
    _rawDescription,
    image,
    imageMobile
  } = props;

  return (
    <div className={styles.root}>
      {((image && image.asset) || (imageMobile && imageMobile.asset)) && (
        <div className={styles.image}>
          <picture>
            {image && image.asset && imageMobile && imageMobile.asset && (
              <>
              <source media="(orientation: landscape)" 
                srcset={imageUrlFor(buildImageObj(image))
                  .auto("format")
                  .url()} 
              />
              <source media="(orientation: portrait)" 
                srcset={imageUrlFor(buildImageObj(imageMobile))
                  .auto("format")
                  .url()} 
              />
              </>
            )}
            {image && image.asset && (
              <img
                src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
                alt={image.alt}
              />
            )}
            {(!image || !image.asset) && (imageMobile && imageMobile.asset) && (
              <img
                src={imageUrlFor(buildImageObj(imageMobile))
                .auto("format")
                .url()}
                alt={imageMobile.alt}
              />
            )}
          </picture>
        </div>
      )}
      {((link && link.linkUrl) || _rawDescription) && (
        <div className={styles.text}>
          <Container>
            {link && link.linkUrl && <Link to={link.linkUrl} className={cn(styles.link, "linkArrowRight", "h1")}>{link.text}<Icon symbol="arrow-right-lg" /></Link>}
            {_rawDescription && <PortableText blocks={_rawDescription} />}
          </Container>
        </div>
      )}
    </div>
  );
}

export default NextPage;
